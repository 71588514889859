/* eslint-disable no-useless-concat */
import React, { useEffect, useState } from "react";

import {
  Box,
  //CircularProgress,
  FormControlLabel,
  Grid,
  Menu,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";

import useStyles from "./Header.styles";
import { startPoll } from "../../redux-saga/redux/profile/myProfile";
import { resetSuccess } from "../../redux-saga/redux/auth/authentication";
import { getWalletDataStart } from "../../redux-saga/redux/wallet";
import { toggleDepositWithdraw } from "../../redux-saga/redux/settings/themeSettings";
import { socket } from "../../services/webSocket/supportChatSocket";
import { formatValueWithoutRoundOf } from "../../utils/helper";

const Wallet = ({ userDetails }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { darkMode } = useSelector((state) => state.themeSettings);
  const { metamaskLoggedIn, isLoggedIn, success, metamaskSuccess } =
    useSelector((state) => state.authentication);

  const { isDepositWithdrawOpen } = useSelector((state) => state.themeSettings);

  const { walletData, loading, updatedBalance } = useSelector(
    (state) => state.wallet
  );

  const [wallet, setWallet] = useState([]);

  const [CoinChangeAccount, setCoinChangeAccount] = useState("");
  const [selectedValue, setSelectedValue] = useState("coin");

  const [isUsdEnable, setIsUsdEnable] = useState(false);

  const handleCheckBox = () => {
    setIsUsdEnable(!isUsdEnable);
  };

  const handleChange = (event) => {
    const selectedValueCopy = selectedValue;
    const userId = Number(localStorage.getItem("useId"));
    localStorage.setItem("selectedWallet", event.target.value);
    socket.emit(
      "update-selected-wallet",
      { userId, selectedWallet: event.target.value },
      (res) => {
        if (!res.updated) {
          toast.error("Wallet Update Failed. Please try again later!", {
            id: "error-in-dismiss-feature",
          });
          setSelectedValue(selectedValueCopy);
          localStorage.setItem(selectedValueCopy);
        }
      }
    );
    if (event.target.value === "gold") {
      toast.success("Gold Coin Mode is Selected", { id: "goldCoin" });
    } else if (event.target.value === "coin") {
      toast.success("Bonus Coin Mode is Selected", { id: "diamondCoin" });
    }
    setSelectedValue(event.target.value);
  };

  const inGame = window.location.href.split("/")[3] === "game" ? true : false;
  useEffect(() => {
    if (metamaskLoggedIn || isLoggedIn) {
      dispatch(startPoll());
      dispatch(resetSuccess());
    }
  }, [dispatch, isLoggedIn, metamaskLoggedIn, metamaskSuccess, success]);

  useEffect(() => {
    if (updatedBalance) {
      setWallet((state) => {
        return state.map((elem) => {
          if (updatedBalance.currency === "HR") {
            return { ...elem, non_cash_amount: updatedBalance.balance };
          } else if (updatedBalance.currency === "USD") {
            return { ...elem, amount: updatedBalance.balance };
          }
          return elem;
        });
      });
    }
  }, [updatedBalance]);

  useEffect(() => {
    const storeValue = localStorage.getItem("selectedWallet");
    if (storeValue === "gold") {
      setSelectedValue("gold");
    } else {
      setSelectedValue("coin");
    }
  }, []);

  useEffect(() => {
    if (!loading && walletData) {
      setWallet(walletData);
    }
  }, [walletData, loading]);

  let paramId = localStorage.getItem("paramId");

  useEffect(() => {
    dispatch(getWalletDataStart());
    return () => {
      localStorage.removeItem("paramId");
    };
  }, [dispatch, paramId]);

  const handleWallet = (event) => {
    if (inGame) {
      toast.error("Wallet is Deactivated During Gameplay", {
        id: "messgaeLength",
      });
    } else {
      dispatch(getWalletDataStart());
      dispatch(toggleDepositWithdraw(!isDepositWithdrawOpen));
    }
  };

  const openCoinChange = Boolean(CoinChangeAccount);

  const handleCoinChange = (event) => {
    setCoinChangeAccount(event.currentTarget);
  };

  const handleCoinCloseChange = () => {
    setCoinChangeAccount(null);
  };
  return (
    <Grid className={`${classes.balanceBtnWrap} balanceBtnMobile`}>
      <Typography component="span" className={`${classes.btnNewPrimary}`}>
        <Box className={classes.balanceFlexBox}>
          {selectedValue === "coin" ? (
            <>
              <Grid className={classes.coinGrid}>
                <div className=" coin_img ">
                  <img src="/images/BonusCoin.png" alt="" />
                </div>
                <span className="walletNumber">
                  {!inGame
                    ? formatValueWithoutRoundOf(wallet[0]?.non_cash_amount) || "0.00"
                    : "(In-Game)"}
                </span>
              </Grid>
            </>
          ) : (
            <>
              <Grid className={classes.coinGrid}>
                <div className="earnSvgIcon headerSvgIcon">
                  <GoldCoinSVG />
                </div>

                {!inGame
                  ? formatValueWithoutRoundOf(wallet[0]?.amount) ||
                    "0.00"
                  : "(In-Game)"}
              </Grid>
            </>
          )}
          <Grid
            className={
              classes.coinChangeContent
            } /*  style={{ display: "none" }} */
          >
            {CoinChangeAccount && !inGame ? (
              <Grid
                className={`${classes.arrowIconWallet} `}
                onClick={handleCoinCloseChange}
              >
                <img
                  src="/images/up-arrrow.svg"
                  alt=""
                  className="arrowiconLandscape"
                />
              </Grid>
            ) : (
              !inGame && (
                <Grid
                  className={classes.arrowIconWallet}
                  onClick={handleCoinChange}
                >
                  <img
                    style={{ transform: "rotate(-180deg)" }}
                    src="/images/up-arrrow.svg"
                    alt=""
                    className="arrowiconLandscape"
                  />{" "}
                </Grid>
              )
            )}
          </Grid>
        </Box>

        <Menu
          id="coin-menu"
          anchorEl={CoinChangeAccount}
          open={openCoinChange}
          className={`${classes.accountMenu} wallet-menu`}
          onClose={handleCoinCloseChange}
          MenuListProps={{
            "aria-labelledby": "account-button",
          }}
        >
          {darkMode ? (
            <div className={classes.profileShapIcon}>
              <img src="/images/top-icon.svg" alt="" />
            </div>
          ) : (
            <div className={classes.profileShapIcon}>
              <img src="/images/w-top-icon.svg" alt="" />
            </div>
          )}

          <Grid className={`${classes.coinContentItem} coin-content-item`}>
            
            <Grid className={classes.coinContentListItem}>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={selectedValue}
                onChange={handleChange}
              >
                <FormControlLabel
                  control={<Radio />}
                  value="coin"
                  className={classes.walletRadio}
                />
                <Grid className={classes.coinContent}>
                  <Grid item className={classes.coinContentList}>
                    <div className="bonus_img">
                      <img src="/images/BonusCoin.png" alt="" />
                    </div>
                    <p>Bonus Coin</p>
                  </Grid>
                  <p>
                    {isUsdEnable
                      ? "$" +
                          `${formatValueWithoutRoundOf(
                            wallet[0]?.non_cash_amount
                          )}` || "0.00"
                      : formatValueWithoutRoundOf(wallet[0]?.non_cash_amount) ||
                        "0.00"}
                  </p>
                </Grid>
              </RadioGroup>
            </Grid>
            <Grid item className={classes.coinContentListItem}>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={selectedValue}
                onChange={handleChange}
              >
                <FormControlLabel
                  control={<Radio />}
                  value="gold"
                  className={classes.walletRadio}
                />

                <Grid className={`${classes.coinContent} coin-border`}>
                  <Grid item className={classes.coinContentList}>
                    <div className="bonus_img">
                      <img src="/images/GoldCoinIcon.png" alt="" />
                    </div>
                    <p>Gold Coin</p>
                  </Grid>
                  <p>
                    {isUsdEnable
                      ? "$" +
                          `${formatValueWithoutRoundOf(wallet[0]?.amount)}` ||
                        "0.00"
                      : formatValueWithoutRoundOf(wallet[0]?.amount) || "0.00"}
                  </p>
                </Grid>
              </RadioGroup>
            </Grid>
          </Grid>

          <div className="usdView">
            <span>View in USD</span>
            <div className="switch">
              <input
                type="checkbox"
                checked={isUsdEnable}
                onChange={handleCheckBox}
              />
              <span className="slider round"></span>
            </div>
          </div>
        </Menu>

        <span
          className={classes.balanceIcon}
          onClick={handleWallet}
          role="presentation"
        >
          {" "}
          {darkMode ? (
            <img src="/images/wallet.svg" alt="Wallet icon" />
          ) : (
            <img src="/images/wallet.svg" alt="Wallet icon" />
          )}
        </span>
      </Typography>
    </Grid>
  );
};

export default Wallet;

// const BonusCoinSvg = () => {
//   return (
//     <svg
//       width="43"
//       height="43"
//       viewBox="0 0 43 43"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <g opacity="0.55" filter="url(#filter0_f_4_41)">
//         <circle cx="21.5" cy="21.5" r="13.5" fill="url(#paint0_radial_4_41)" />
//         <circle cx="21.5" cy="21.5" r="13" stroke="url(#paint1_linear_4_41)" />
//       </g>
//       <circle
//         cx="21.5"
//         cy="18.5"
//         r="13"
//         fill="url(#paint2_radial_4_41)"
//         stroke="url(#paint3_linear_4_41)"
//       />
//       <g filter="url(#filter1_di_4_41)">
//         <circle
//           cx="21.5"
//           cy="18.5"
//           r="10.125"
//           fill="url(#paint4_linear_4_41)"
//         />
//       </g>
//       <g filter="url(#filter2_d_4_41)">
//         <path
//           fillRule="evenodd"
//           clipRule="evenodd"
//           d="M27.627 15.9571L24.9631 13.2932C24.8944 13.2245 24.8013 13.1859 24.7041 13.1859H23.3477L24.3869 15.9571H27.627ZM15.25 16.6944H18.5943L20.6009 23.3831L15.25 16.6944ZM22.3991 23.3831L24.4057 16.6944H27.75L22.3991 23.3831ZM19.6523 13.1859L18.6131 15.9571H15.373L18.0369 13.2932C18.1055 13.2245 18.1987 13.1859 18.2958 13.1859H19.6523ZM23.6359 16.6944H19.3641L21.5 23.8141L23.6359 16.6944ZM23.5994 15.9571H19.4006L20.4398 13.1859H22.5602L23.5994 15.9571Z"
//           fill="#A822BF"
//         />
//       </g>
//       <defs>
//         <filter
//           id="filter0_f_4_41"
//           x="0"
//           y="0"
//           width="43"
//           height="43"
//           filterUnits="userSpaceOnUse"
//           colorInterpolationFilters="sRGB"
//         >
//           <feFlood floodOpacity="0" result="BackgroundImageFix" />
//           <feBlend
//             mode="normal"
//             in="SourceGraphic"
//             in2="BackgroundImageFix"
//             result="shape"
//           />
//           <feGaussianBlur
//             stdDeviation="4"
//             result="effect1_foregroundBlur_4_41"
//           />
//         </filter>
//         <filter
//           id="filter1_di_4_41"
//           x="7.37499"
//           y="8.37497"
//           width="28.25"
//           height="28.25"
//           filterUnits="userSpaceOnUse"
//           colorInterpolationFilters="sRGB"
//         >
//           <feFlood floodOpacity="0" result="BackgroundImageFix" />
//           <feColorMatrix
//             in="SourceAlpha"
//             type="matrix"
//             values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
//             result="hardAlpha"
//           />
//           <feOffset dy="4" />
//           <feGaussianBlur stdDeviation="2" />
//           <feComposite in2="hardAlpha" operator="out" />
//           <feColorMatrix
//             type="matrix"
//             values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
//           />
//           <feBlend
//             mode="normal"
//             in2="BackgroundImageFix"
//             result="effect1_dropShadow_4_41"
//           />
//           <feBlend
//             mode="normal"
//             in="SourceGraphic"
//             in2="effect1_dropShadow_4_41"
//             result="shape"
//           />
//           <feColorMatrix
//             in="SourceAlpha"
//             type="matrix"
//             values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
//             result="hardAlpha"
//           />
//           <feOffset dy="1" />
//           <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
//           <feColorMatrix
//             type="matrix"
//             values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.24 0"
//           />
//           <feBlend
//             mode="normal"
//             in2="shape"
//             result="effect2_innerShadow_4_41"
//           />
//         </filter>
//         <filter
//           id="filter2_d_4_41"
//           x="15.25"
//           y="13.1859"
//           width="12.5"
//           height="11.6282"
//           filterUnits="userSpaceOnUse"
//           colorInterpolationFilters="sRGB"
//         >
//           <feFlood floodOpacity="0" result="BackgroundImageFix" />
//           <feColorMatrix
//             in="SourceAlpha"
//             type="matrix"
//             values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
//             result="hardAlpha"
//           />
//           <feOffset dy="1" />
//           <feComposite in2="hardAlpha" operator="out" />
//           <feColorMatrix
//             type="matrix"
//             values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
//           />
//           <feBlend
//             mode="normal"
//             in2="BackgroundImageFix"
//             result="effect1_dropShadow_4_41"
//           />
//           <feBlend
//             mode="normal"
//             in="SourceGraphic"
//             in2="effect1_dropShadow_4_41"
//             result="shape"
//           />
//         </filter>
//         <radialGradient
//           id="paint0_radial_4_41"
//           cx="0"
//           cy="0"
//           r="1"
//           gradientUnits="userSpaceOnUse"
//           gradientTransform="translate(21.5 35) rotate(-90) scale(27)"
//         >
//           <stop stopColor="#800F93" />
//           <stop offset="1" stopColor="#E483F5" />
//         </radialGradient>
//         <linearGradient
//           id="paint1_linear_4_41"
//           x1="21.5"
//           y1="8"
//           x2="21.5"
//           y2="35"
//           gradientUnits="userSpaceOnUse"
//         >
//           <stop stopColor="white" stopOpacity="0.75" />
//           <stop offset="1" stopColor="white" stopOpacity="0" />
//         </linearGradient>
//         <radialGradient
//           id="paint2_radial_4_41"
//           cx="0"
//           cy="0"
//           r="1"
//           gradientUnits="userSpaceOnUse"
//           gradientTransform="translate(21.5 32) rotate(-90) scale(27)"
//         >
//           <stop stopColor="#750D87" />
//           <stop offset="1" stopColor="#EFA4FC" />
//         </radialGradient>
//         <linearGradient
//           id="paint3_linear_4_41"
//           x1="21.5"
//           y1="5"
//           x2="21.5"
//           y2="32"
//           gradientUnits="userSpaceOnUse"
//         >
//           <stop stopColor="white" stopOpacity="0.75" />
//           <stop offset="1" stopColor="white" stopOpacity="0" />
//         </linearGradient>
//         <linearGradient
//           id="paint4_linear_4_41"
//           x1="21.5"
//           y1="41.7875"
//           x2="21.5"
//           y2="8.37497"
//           gradientUnits="userSpaceOnUse"
//         >
//           <stop stopColor="#A557B2" />
//           <stop offset="1" stopColor="#F6BFFE" />
//         </linearGradient>
//       </defs>
//     </svg>
//   );
// };
const GoldCoinSVG = () => {
  return (
    
<svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.3" filter="url(#filter0_f_4_52)">
<circle cx="21.5" cy="21.5" r="13.5" fill="url(#paint0_radial_4_52)"/>
<circle cx="21.5" cy="21.5" r="13" stroke="url(#paint1_linear_4_52)"/>
</g>
<circle cx="21.5" cy="18.5" r="13" fill="url(#paint2_radial_4_52)" stroke="url(#paint3_linear_4_52)"/>
<g filter="url(#filter1_di_4_52)">
<circle cx="21.5" cy="18.5" r="10.125" fill="url(#paint4_linear_4_52)"/>
</g>
<g filter="url(#filter2_d_4_52)">
<path d="M17.5417 21.1251L15.875 14.6966L19.625 18.4466L21.7083 13.6251L23.375 18.4466L27.125 14.6966L25.875 21.1251H17.5417Z" fill="#CD7400"/>
<path d="M17.6058 22.0626H25.8681V23.0001H17.6058V22.0626Z" fill="#CD7400"/>
</g>
<defs>
<filter id="filter0_f_4_52" x="0" y="0" width="43" height="43" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="4" result="effect1_foregroundBlur_4_52"/>
</filter>
<filter id="filter1_di_4_52" x="7.37499" y="8.37503" width="28.25" height="28.25" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_52"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4_52" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.24 0"/>
<feBlend mode="normal" in2="shape" result="effect2_innerShadow_4_52"/>
</filter>
<filter id="filter2_d_4_52" x="15.875" y="13.6251" width="11.25" height="10.375" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_52"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4_52" result="shape"/>
</filter>
<radialGradient id="paint0_radial_4_52" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(21.5 35) rotate(-90) scale(27)">
<stop stopColor="#FF8A00"/>
<stop offset="1" stopColor="#FFC803"/>
</radialGradient>
<linearGradient id="paint1_linear_4_52" x1="21.5" y1="8" x2="21.5" y2="35" gradientUnits="userSpaceOnUse">
<stop stopColor="white" stopOpacity="0.75"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<radialGradient id="paint2_radial_4_52" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(21.5 32) rotate(-90) scale(27)">
<stop stopColor="#FF8A00"/>
<stop offset="1" stopColor="#FFC803"/>
</radialGradient>
<linearGradient id="paint3_linear_4_52" x1="21.5" y1="5" x2="21.5" y2="32" gradientUnits="userSpaceOnUse">
<stop stopColor="white" stopOpacity="0.75"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint4_linear_4_52" x1="21.5" y1="41.7875" x2="21.5" y2="8.37503" gradientUnits="userSpaceOnUse">
<stop stopColor="#FF8A00"/>
<stop offset="1" stopColor="#FFEA7B"/>
</linearGradient>
</defs>
</svg>

  );
};
